import { IUserGroupModel } from "@/types/models/UserGroup";

export default class UserGroupModel {
  public code: string | null;
  public description: string | null;
  public id: number | null;
  public name: string | null;

  constructor({ code, description, id, name }: IUserGroupModel) {
    this.code = code;
    this.description = description;
    this.id = id;
    this.name = name;
  }
}
