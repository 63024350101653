import AbstractFactory from "@/models/factories/AbstractFactory";
import UserGroupModel from "@/models/UserGroupModel";

class UserGroupFactory extends AbstractFactory {
  create({ object = {}, options = {} }) {
    return new UserGroupModel({
      id: null,
      name: null,
      description: null,
      code: null,
      ...object,
    });
  }
}

export default new UserGroupFactory();
