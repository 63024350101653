import AbstractFactory from "@/models/factories/AbstractFactory";
import UserGroupFactory from "@/models/factories/UserGroupFactory";
import { IUserModel } from "@/types/models/User";
import { IUserGroupModel } from "@/types/models/UserGroup";

class UserFactory extends AbstractFactory {
  create(object: Partial<IUserModel> = {}) {
    const { groups = [] } = object;
    return {
      id: 0,
      name: "",
      registered: null,
      phone: null,
      mother_name: "",
      genre: 0,
      father_name: null,
      email: "",
      cellphone: "",
      marital_status: { id: 0, description: "" },
      cpf: "",
      birthdate: "",
      naturalness: null,
      owned_by: null,
      url_from: null,
      ...object,
      groups: groups.map((item: IUserGroupModel) => UserGroupFactory.create({ object: item })),
    } as IUserModel;
  }
}

export default new UserFactory();
